import {TemplateInstance} from '@github/template-parts'
// eslint-disable-next-line no-restricted-imports
import {observe} from 'selector-observer'

const POLL_WAIT_TIME_MS = 3000

function addFlashMessage(message: string): void {
  const template = document.querySelector<HTMLTemplateElement>('template.js-flash-template')!
  template.after(new TemplateInstance(template, {className: 'flash-error', message}))
}

function hidePromptAndShowErrorMessage(): void {
  document.getElementById('github-mobile-authenticate-prompt')!.hidden = true
  document.getElementById('github-mobile-authenticate-error-and-retry')!.hidden = false
}

// the user has approved the mobile request - reload the page which will trigger the redirects
//
// token - An optional parameter - only used during the password reset flow
function approvedHandler(token?: string): void {
  let url
  if (token) {
    // during password reset, when the user approves the mobile request, we need to update the url
    // to use the new SAT token instead of the old SAT token
    url = new URL(`password_reset/${encodeURIComponent(token)}`, window.location.origin)
  } else {
    url = new URL('', window.location.href)
  }
  url.searchParams.set('redirect', 'true')
  window.location.assign(url)
}

function expiredHandler(): void {
  const message = document.getElementById('github-mobile-authenticate-error-and-retry')!.getAttribute('timeout-flash')
  if (message) {
    addFlashMessage(message)
  }
  hidePromptAndShowErrorMessage()
}

// two_factor_login info erased from session - send them back to login form
function rejectedHandler(): void {
  document.getElementById('github-mobile-rejected-redirect')!.click()
}

function genericHandler(): void {
  const message = document.getElementById('github-mobile-authenticate-error-and-retry')!.getAttribute('error-flash')
  if (message) {
    addFlashMessage(message)
  }
  hidePromptAndShowErrorMessage()
}

function pollMobileAuthRequestStatus(url: RequestInfo): Promise<void> {
  return (async function poll(errorCount: number): Promise<void> {
    let status = 'STATUS_UNKNOWN'
    let token
    try {
      const form = document.getElementById('github-mobile-authenticate-form') as HTMLFormElement
      const csrfInput = form.querySelector('.js-data-url-csrf') as HTMLInputElement
      const response = await self.fetch(
        new Request(url, {
          method: 'POST',
          body: new FormData(form),
          mode: 'same-origin',
          headers: {
            Accept: 'application/json',
            'Scoped-CSRF-Token': csrfInput.value,
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      )
      if (response.ok) {
        const responseJSON = await response.json()
        status = responseJSON.status
        token = responseJSON.token
      } else {
        status = 'STATUS_ERROR'
      }
    } catch {
      status = 'STATUS_ERROR'
    }

    switch (status) {
      case 'STATUS_APPROVED':
        // stop polling and handle approved case specifically
        return approvedHandler(token)
      case 'STATUS_EXPIRED':
        // stop polling and handle expired case specifically
        return expiredHandler()
      case 'STATUS_ACTIVE':
      case 'STATUS_ERROR':
      case 'STATUS_UNKNOWN':
        // keep polling
        break
      case 'STATUS_REJECTED':
        // stop polling and handle rejected case specifically
        return rejectedHandler()
      case 'STATUS_NOT_FOUND': // not expected, we shouldn't reach the poller if this is the case
      case 'STATUS_UNSUPPORTED': // not expected, we shouldn't reach the poller if this is the case
      default:
        // stop polling and handle these cases generically
        return genericHandler()
    }

    // wait between polls
    await new Promise(resolve => setTimeout(resolve, POLL_WAIT_TIME_MS))
    poll(errorCount)
  })(0)
}

async function initializeMobileAuthRequestStatusPoll(el: Element) {
  try {
    await pollMobileAuthRequestStatus(el.getAttribute('data-poll-url')!)
  } catch (e) {
    // if an error was thrown that we didn't handle properly in the poller
    // we need to make sure to still update the UI in a meaningful way
    return genericHandler()
  }
}

observe('.js-poll-github-mobile-two-factor-authenticate', function (el) {
  initializeMobileAuthRequestStatusPoll(el)
})

observe('.js-poll-github-mobile-verified-device-authenticate', function (el) {
  initializeMobileAuthRequestStatusPoll(el)
})

observe('.js-poll-github-mobile-two-factor-password-reset-authenticate', function (el) {
  initializeMobileAuthRequestStatusPoll(el)
})
